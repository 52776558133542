<template>
  <main id="content" role="main" class="overflow-hidden">

    <!-- ========== Hero Section ========== -->
    <div class="position-relative bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-solution-hero.svg') + ')'}">
      <div class="container space-top-3 space-bottom-2 position-relative">
        <div class="row justify-content-lg-between align-items-lg-center">
          <div class="col-md-6">
            <div class="mb-5 mb-md-0">
              <span class="d-block small font-weight-bold text-cap mb-2">our solutions</span>
              <h1>API-as-a-Product Design &amp; Development</h1>
            </div>
          </div>
          <div class="col-md-6">
            <img class="img-fluid" src="@/assets/svg/illustrations/api-economy-product.svg">
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Hero Section ========== -->

    <!-- ========== Overview Section ========== -->
    <div class="container space-2 space-lg-3">
      <div class="w-md-80 text-center mx-md-auto mb-5 mb-md-9">
        <span class="d-block small font-weight-bold text-cap mb-2">Building API-as-a-Product</span>
        <h2>3 Steps to Launch</h2>
      </div>
      <div class="row">
        <div class="col-md-4 mb-5 mb-md-0">
          <div class="text-center">
            <figure class="max-w-10rem mx-auto mb-4">
              <img class="img-fluid" src="@/assets/svg/icons/icon-36.svg" alt="SVG">
            </figure>
            <h3>API Product Design Thinking</h3>
          </div>
        </div>

        <div class="col-md-4 mb-5 mb-md-0">
          <div class="text-center">
            <figure class="max-w-10rem mx-auto mb-4">
              <img class="img-fluid" src="@/assets/svg/icons/icon-29.svg" alt="SVG">
            </figure>
            <h3>Lean API Product Development</h3>
          </div>
        </div>

        <div class="col-md-4">
          <div class="text-center">
            <figure class="max-w-10rem mx-auto mb-4">
              <img class="img-fluid" src="@/assets/svg/icons/icon-52.svg" alt="SVG">
            </figure>
            <h3>Launch to API Marketplaces</h3>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Overview Section ========== -->

    <!-- ========== Reference Industries Section ========== -->
    <div class="position-relative bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-about-hero.svg') + ')'}">
      <div class="container space-2 space-lg-3">
        <div class="w-md-80 text-center mx-md-auto mb-5 mb-md-9">
          <span class="d-block small font-weight-bold text-cap mb-2">Reference API-as-a-Product</span>
          <h2>API Product Use Cases</h2>
        </div>

        <div class="row align-items-lg-center mb-11">
          <div class="col-md-6 order-md-1 mb-8" data-aos="fade-left" data-aos-delay="0">
            <div class="position-relative h-100 w-80 mx-auto float-left">
              <img class="img-fluid rounded" src="@/assets/svg/illustrations/industries/education-banner-catalogue.svg">
              <div class="position-absolute top-0 right-0 w-100 h-100 rounded z-index-n1 mt-5 mr-n5" style="background-color:#84ccef" />
            </div>
          </div>
          <div class="col-md-6">
            <h3 class="mb-3">Education</h3>
            <p>Learning Management System (LMS) APIs with administrative features for modern online learning centres.</p>
            <a class="font-weight-bold" href="javascript:;" data-toggle="modal" data-target="#benGeneralForm" data-gtm-att="ga-solution-apiproduct-request-reference-case-button">Request Reference Case<i class="fas fa-angle-right fa-sm ml-2" /></a>
          </div>
        </div>

        <div class="row align-items-lg-center mb-11">
          <div class="col-md-6 mb-8" data-aos="fade-right">
            <div class="position-relative h-100 w-80 mx-auto float-left">
              <img class="img-fluid rounded" src="@/assets/svg/illustrations/industries/fs-banner-catalogue.svg">
              <div class="position-absolute top-0 right-0 w-100 h-100 rounded z-index-n1 mt-5 mr-n5" style="background-color:#007fb6" />
            </div>
          </div>
          <div class="col-md-6">
            <h3 class="mb-3">Financial services</h3>
            <p>Payment Collection APIs with live notification and reconciliation features in placed. </p>
            <a class="font-weight-bold" href="javascript:;" data-toggle="modal" data-target="#benGeneralForm" data-gtm-att="ga-solution-apiproduct-request-reference-case-button">Request Reference Case<i class="fas fa-angle-right fa-sm ml-2" /></a>
          </div>
        </div>

        <div class="row align-items-lg-center mb-11">
          <div class="col-md-6 order-md-1 mb-8" data-aos="fade-left">
            <div class="position-relative h-100 w-80 mx-auto float-left">
              <img class="img-fluid rounded" src="@/assets/svg/illustrations/industries/health-banner-catalogue.svg">
              <div class="position-absolute top-0 right-0 w-100 h-100 rounded z-index-n1 mt-5 mr-n5" style="background-color:#4ab0e9" />
            </div>
          </div>
          <div class="col-md-6">
            <h3 class="mb-3">Healthcare</h3>
            <p>Fast Healthcare Interoperability Resources (FHIR) data API for health data exchange.</p>
            <a class="font-weight-bold" href="javascript:;" data-toggle="modal" data-target="#benGeneralForm" data-gtm-att="ga-solution-apiproduct-request-reference-case-button">Request Reference Case<i class="fas fa-angle-right fa-sm ml-2" /></a>
          </div>
        </div>

        <div class="text-center" data-aos="fade-up">
          <h2>Missing Your Use Cases?</h2>
          <p class="lead">Talk to Us for more information</p>
          <span class="d-block mt-5">
            <a id="ga-solution-apiproduct-contact-us-button" class="btn btn-primary transition-3d-hover" href="javascript:;" data-toggle="modal" data-target="#benGeneralForm">Contact Us</a>
          </span>
        </div>
      </div>
    </div>
    <!-- ========== End Reference Industries Section ========== -->

    <!-- ==========  CTA Section ==========  -->
    <div class="container space-2 space-lg-3">
      <div class="w-md-80 text-center mx-md-auto mb-5">
        <span class="d-block small font-weight-bold text-cap mb-2">you're in good company</span>
        <h2>Powering Innovative Enterprises</h2>
      </div>
      <PartnerCarousel :items="api_partner_list" />
      <div class="mt-4">
        <ContactUsBar :detail="{ title: 'Interested in Partnering with Us?' }" />
      </div>
    </div>
    <!-- ========== End CTA Section ==========  -->

    <!-- ========== More Solutions Section ==========-->
    <div
      class="space-2 space-lg-3 bg-blue-light"
      :style="{'background-image': 'url(' + require('@/assets/svg/components/abstract-shapes-9-1-01.svg') + ')',
               backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}"
    >
      <div class="w-md-80 w-lg-50 text-center mx-md-auto mb-5">
        <span class="d-block small font-weight-bold text-cap mb-2">More by beNovelty</span>
        <h2>Want to Know More About API Economy?</h2>
      </div>
      <div class="position-relative">
        <div class="container">
          <div
            class="js-slick-carousel slick slick-equal-height slick-gutters-3"
            data-hs-slick-carousel-options="{
                &quot;prevArrow&quot;: &quot;<span class=\&quot;fa fa-arrow-left slick-arrow slick-arrow-primary-white slick-arrow-left slick-arrow-centered-y shadow-soft rounded-circle ml-sm-n2 d-none d-lg-inline\&quot;></span>&quot;,
                &quot;nextArrow&quot;: &quot;<span class=\&quot;fa fa-arrow-right slick-arrow slick-arrow-primary-white slick-arrow-right slick-arrow-centered-y shadow-soft rounded-circle mr-sm-n2 d-none d-lg-inline\&quot;></span>&quot;,
                &quot;infinite&quot;: true,
                &quot;dots&quot;: true,
                &quot;dotsClass&quot;: &quot;slick-pagination d-lg-none&quot;,
                &quot;autoplay&quot;: true,
                &quot;autoplaySpeed&quot;: 5000,
                &quot;slidesToShow&quot;: 3,
                &quot;responsive&quot;: [{
                  &quot;breakpoint&quot;: 1200,
                    &quot;settings&quot;: {
                      &quot;slidesToShow&quot;: 3
                    }
                  }, {
                  &quot;breakpoint&quot;: 992,
                    &quot;settings&quot;: {
                      &quot;slidesToShow&quot;: 2
                    }
                  }, {
                  &quot;breakpoint&quot;: 768,
                  &quot;settings&quot;: {
                    &quot;slidesToShow&quot;: 1
                  }
                  }]
              }"
          >
            <div class="js-slide">
              <div class="card my-5">
                <div class="card-body pb-0">
                  <img class="img-fluid max-w-8rem mb-4" src="@/assets/svg/icons/icon-4.svg">
                  <h4>API Consultancy</h4>
                  <p>Read more to find out what makes beNovelty different in API consultancy.</p>
                </div>
                <div class="card-footer pt-0 border-0 bg-transparent">
                  <!-- <a id="ga-solutions-more-by-benovelty-api-consultancy-button" href="/solutions/api-consultancy"> -->
                  <router-link id="ga-solutions-more-by-benovelty-api-consultancy-button" :to="{ path: '/solutions/api-consultancy' }">
                    Learn More <i class="fas fa-angle-right fa-sm ml-1" />
                  </router-link>
                  <!-- </a> -->
                </div>
              </div>
            </div>
            <!-- <div class="js-slide">
              <div class="card my-5">
                <div class="card-body pb-0">
                  <img class="img-fluid max-w-8rem mb-4" src="@/assets/svg/icons/icon-28.svg">
                  <h4>API Product</h4>
                  <p>Find out the 3 simple steps for building API-as-a-Products.</p>
                </div>
                <div class="card-footer pt-0 border-0 bg-transparent">
                  <a id="ga-solutions-more-by-benovelty-api-product-button" href="/solutions/api-product">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></a>
                </div>
              </div>
            </div> -->
            <div class="js-slide">
              <div class="card my-5">
                <div class="card-body pb-0">
                  <img class="img-fluid max-w-8rem mb-4" src="@/assets/svg/icons/icon-3.svg">
                  <h4>API Management</h4>
                  <p>Learn how to manage, secure, mediate, analyze complex enterprise API programme with API Management.</p>
                </div>
                <div class="card-footer pt-0 border-0 bg-transparent">
                  <!-- <a id="ga-solutions-more-by-benovelty-api-management-button" href="/solutions/api-management"> -->
                  <router-link id="ga-solutions-more-by-benovelty-api-management-button" :to="{ path: '/solutions/api-management' }">
                    Learn More <i class="fas fa-angle-right fa-sm ml-1" />
                  </router-link>
                  <!-- </a> -->
                </div>
              </div>
            </div>
            <div class="js-slide">
              <div class="card my-5">
                <div class="card-body pb-0">
                  <img class="img-fluid max-w-8rem mb-4" src="@/assets/svg/icons/icon-69.svg">
                  <h4>API Community</h4>
                  <p>Discover more on our latest API community events.</p>
                </div>
                <div class="card-footer pt-0 border-0 bg-transparent">
                  <!-- <a id="ga-solutions-more-by-benovelty-api-community-button" href="/solutions/api-community"> -->
                  <router-link id="ga-solutions-more-by-benovelty-api-community-button" :to="{ path: '/solutions/api-community' }">
                    Learn More <i class="fas fa-angle-right fa-sm ml-1" />
                  </router-link>
                  <!-- </a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Solutions Section ========== -->

  </main>
</template>

<script>
import 'bootstrap'
const $ = require('jquery')
import PartnerCarousel from '@/components/partner-carousel'
import ContactUsBar from '@/components/contact-us-bar'
import HSGoTo from '../../../assets/vendor/hs-go-to/src/js/hs-go-to'
import '../../../assets/js/hs.core.js'
import '../../../assets/js/hs.slick-carousel.js'
import '../../../assets/vendor/slick-carousel/slick/slick.js'
import { mapGetters } from 'vuex'
export default {
  name: 'ApiProduct',
  components: {
    PartnerCarousel,
    ContactUsBar
  },
  data() {
    return {
      api_partner_list: []
    }
  },
  computed: {
    ...mapGetters([
      'partners'
    ])
  },
  async mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    $('.js-slick-carousel').each(function() {
      $.HSCore.components.HSSlickCarousel.init($(this))
    })
  },
  created() {
    this.api_partner_list = this.$store.getters.partners.api_partner_list
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'API Product | Solutions | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/solutions/api-product' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'API Product | Solutions | beNovelty' },
        { property: 'og:description', content: 'Rapid API development & prototyping with leading edge tooling & assets in our sandbox environment.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/solutions/api-product' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../../assets/vendor/slick-carousel/slick/slick.css");
</style>
